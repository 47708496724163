export default [
  "any",
  "ask",
  "bun/buns",
  "ce/cir",
  "e/em",
  "fae/faer",
  "he/him",
  "he/they",
  "it/its",
  "none",
  "per/pers",
  "she/her",
  "she/they",
  "sie/sier",
  "they/them",
  "ve/vir",
  "xe/xem",
  "xe/xim",
  "xe/hir",
  "ze/zan",
  "ze/zir",
  "zhe/zhim",
  "zhie/zir"
]
